import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DelegationService {
  private _numEmployer: number;
  private _isResponsible: any;
  private _currentSociety: any;

  constructor() {
    const storedValue = localStorage.getItem('numEmployer');
    if (storedValue) {
      this._numEmployer = parseInt(storedValue, 10);
    }
  }

  get numEmployer(): number {
    return this._numEmployer;
  }

  set numEmployer(value: number) {
    this._numEmployer = value;
    localStorage.setItem('numEmployer', value.toString());
  }

  get isResponsible() {
    this._isResponsible = localStorage.getItem('isResponsible');
    return this._isResponsible;
  }

  set isResponsible(value: any) {
    this._isResponsible = value;
    localStorage.setItem('isResponsible', value.toString());
  }

  get currentSociety() {
    this._currentSociety = localStorage.getItem('currentSociety');
    return this._currentSociety;
  }

  set currentSociety(value: any) {
    this._currentSociety = value;
    localStorage.setItem('currentSociety', value.toString());
  }
}
